import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
// import video from '../assets/video/headerVideo.mp4';
import logo from '../assets/images/png/favicon.png';
import LetterMeaning from '../components/LetterMeaning';
import map from '../assets/images/svg/texasMap.svg';
import server from '../assets/images/banner/serverRoom.png';
import texas from "../assets/images/banner/landTexas.png";
import energy from "../assets/images/banner/energy.png";
import mining from "../assets/images/banner/bitcoinBlue.png";
import secure from "../assets/images/svg/sec.svg";
import reliable from "../assets/images/svg/reli.svg";
import equipment from "../assets/images/svg/equi.svg";
import customer from "../assets/images/svg/cust.svg";
import clean from "../assets/images/svg/nat.svg";
import wind from "../assets/images/svg/win.svg";
import solar from "../assets/images/svg/sol.svg";
import geo from "../assets/images/svg/geo.svg";
import landscape from "../assets/images/banner/texasLandscape.png";
// import GoogleMap from '../components/GoogleMap';
import ContactForm from './ContactForm';

const Home = () => {
  return (
    <>
      {/* ================ banner ============== */}
      <div className="banner">
        <video className="banner-video" src="https://all-sites-under.s3.us-east-2.amazonaws.com/cremtex/headerVideo.mp4" autoPlay loop />
        <div className="overlay"></div>

        <div className="banner-content-wrap">
          <h2 className="text-center banner-title">
            Crypto Mining Infrastructure
          </h2>
          <p className="text-center banner-content">
            TEXAS at the center of the CryptoVerse.
          </p>
        </div>
      </div>
      {/* ================ banner ============== */}

      {/* ================ description =============== */}
      <div className="description-wrap">
        <Container>
          <div className="d-flex justify-content-center logo-wrap">
            <img src={logo} alt="logo" />
          </div>
          <div className="description-content-wrap">
            <h4 className="description-title text-center">
              BRINGING IT ALL TOGETHER.
            </h4>
            <p className="desc text-center">
              CREMTex builds superior facilities for miners to enjoy a superior
              mining experience. We provide world-class infrastructure, secure
              hosting conditions, and crucial services to power the future of
              crypto mining!
            </p>
          </div>

          <Row className="letter-box-wrap">
            <Col md={3} xs={6}>
              <LetterMeaning letter="C" text="Co-Hosting" />
            </Col>
            <Col md={3} xs={6}>
              <LetterMeaning letter="R" text="Real Estate" />
            </Col>
            <Col md={3} xs={6}>
              <LetterMeaning letter="E" text="Energy" />
            </Col>
            <Col md={3} xs={6}>
              <LetterMeaning letter="M" text="Mining" />
            </Col>
          </Row>
        </Container>
      </div>
      {/* ================ description =============== */}

      {/* ================ mining map =============== */}
      <div className="mining-map-wrap">
        <h3 className="map-title text-center">
          THE BEST MINING INFRASTRUCTURE IN TEXAS
        </h3>

        <div className="d-flex justify-content-center">
          <img src={map} alt="map" className="map-img" />
        </div>
      </div>
      {/* ================ mining map =============== */}

      {/* ================ quote =============== */}
      <div className="quote-wrap">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h3 className="quote text-center">
                "Blockchain is a booming industry that Texas needs to be
                involved in."
              </h3>
              <p className="writer text-center">~Greg Abbott</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ================ quote =============== */}

      {/* ================ features =============== */}
      <div className="feature-wrap" id="co-host">
        <Container>
          <div className="feature-box-wrap">
            <Row>
              <Col md={3}>
                <div className="letter-wrap">
                  <h1 className="single-letter text-center">C</h1>
                  <p className="single-meaning text-center">CO-HOSTING</p>
                </div>
              </Col>
              <Col md={9} lg={8}>
                <img src={server} alt="server" className="feature-img" />

                <p className="feature-text">
                  Customized hosting and infrastructure solutions for large
                  scale miners pouring into Texas from around the world. Built
                  for scalability, sustainability and above all security.
                  CREMTex offers attractive payment structures to establish
                  trust and create long term relationships with miners.
                </p>

                <Row className="feature-icons-wrap">
                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img src={secure} alt="secure" className="feature-icon" />
                      <p className="feature-title">SECURE</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img
                        src={reliable}
                        alt="reliable"
                        className="feature-icon"
                      />
                      <p className="feature-title">RELIABLE</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img
                        src={equipment}
                        alt="EQUIPMENT"
                        className="feature-icon"
                      />
                      <p className="feature-title">EQUIPMENT UPKEEP</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img
                        src={customer}
                        alt="customer"
                        className="feature-icon"
                      />
                      <p className="feature-title">CUSTOMER SERVICE</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="feature-box-wrap" id="real-estate">
            <Row>
              <Col md={3}>
                <div className="letter-wrap">
                  <h1 className="single-letter text-center">R</h1>
                  <p className="single-meaning text-center">REAL ESTATE</p>
                </div>
              </Col>
              <Col md={9} lg={8}>
                <img src={texas} alt="server" className="feature-img" />

                <p className="feature-text">
                  Real estate is at the foundation of cryptomining. CREMTex
                  sources prime-space and energy-accessible properties from 235
                  acres to 140,000 acres all across Texas. Large industrial
                  infrastructure that can be easily adaptable to mining needs
                  and proximal to an affordable power source.
                </p>
              </Col>
            </Row>
          </div>

          <div className="feature-box-wrap" id="energy">
            <Row>
              <Col md={3}>
                <div className="letter-wrap">
                  <h1 className="single-letter text-center">E</h1>
                  <p className="single-meaning text-center">ENERGY</p>
                </div>
              </Col>
              <Col md={9} lg={8}>
                <img src={energy} alt="server" className="feature-img" />

                <p className="feature-text">
                  Texas is a powerhouse of abundant sources of energy. CREMTex
                  collaborates with multi sector energy companies to supply
                  clean and sustainable energy to miners within our network. We
                  strive to mine bitcoin and other cryptocurrencies with the
                  greenest energy and help reduce greenhouse gas emissions
                  proactively with our energy providers.
                </p>

                <Row className="feature-icons-wrap">
                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img src={clean} alt="secure" className="feature-icon" />
                      <p className="feature-title">CLEAN ENERGY</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img src={wind} alt="reliable" className="feature-icon" />
                      <p className="feature-title">WIND</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img
                        src={solar}
                        alt="EQUIPMENT"
                        className="feature-icon"
                      />
                      <p className="feature-title">SOLAR</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div className="feature-icon-box">
                      <img src={geo} alt="customer" className="feature-icon" />
                      <p className="feature-title">GEO-THERMAL</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="feature-box-wrap" id="mining">
            <Row>
              <Col md={3}>
                <div className="letter-wrap">
                  <h1 className="single-letter text-center">M</h1>
                  <p className="single-meaning text-center">MINING</p>
                </div>
              </Col>
              <Col md={9} lg={8}>
                <img src={mining} alt="server" className="feature-img" />

                <p className="feature-text">
                  We relocate medium to large operations from all parts of the
                  world and help them settle in Texas. Our operation streamlines
                  the process to get all our miners up and running as soon a
                  shipment arrives at a port, within 30 days or less.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* ================ features =============== */}

      {/* ================ about =============== */}
      <div className="about-wrap position-relative" id="texas">
        <img src={landscape} alt="" className="about-img" />
        <div className="overlay"></div>
        <Container className="h-100">
          <div className="about-content-wrap">
            <h4 className="about-title text-white text-center">Why Texas</h4>
            <p className="about-text text-white text-center">
              Texas boasts some of the lowest energy prices in the world. It is
              famed for its southern hospitality. It has emerged as a visionary
              leader poised to usher the future of crypto and blockchain into
              mainstream America. CREMTex makes mining simple and efficient. By
              mining high-tech PoS PoW PoC cryptocurrencies, CREMTex provides
              services that create value for generations to come.
            </p>
          </div>
        </Container>
      </div>
      {/* ================ about =============== */}

      {/* ================ contact =============== */}
      <div className="contact-wrap" id="contact">
        <Container>
          <h4 className="contact-section-title">Contact Information</h4>

          {/* <div className="google-map-wrap">
            <GoogleMap /> Add API Key in this component
          </div> */}

          <div className="contact-info">
            <Row className='gy-4'>
              <Col md={4} xs={6}>
                <h6 className="contact-title">Address</h6>
                <div className="contact-desc">
                  2150 Town Square Place, Ste 200, Sugar Land, TX – 77479.
                </div>
              </Col>

              <Col md={4} xs={6}>
                <h6 className="contact-title">Contact Number</h6>
                <div className="contact-desc">Mobile: 806-420-0931</div>
              </Col>

              <Col md={4} xs={6}>
                <h6 className="contact-title">Office Hours</h6>
                <div className="contact-desc">Monday - Friday</div>
                <div className="contact-desc">8:30am - 5:00pm</div>
              </Col>
            </Row>
          </div>

          <div className="contact-form-wrap">
            <p className="form-title">Complete and submit the form below</p>

            <ContactForm />
          </div>
        </Container>
      </div>
      {/* ================ contact =============== */}
    </>
  );
}

export default Home
