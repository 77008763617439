import React from "react";

const LetterMeaning = (props) => {
  return (
    <div className="letter-box">
      <h1 className="letter text-center">{props.letter}</h1>
      <p className="letter-text text-center">{props.text}</p>
    </div>
  );
};

export default LetterMeaning;
