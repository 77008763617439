import React from 'react'

const Policy = () => {
  return (
    <div className="policy-wrap">
      <p className="title text-center">
        <span>W</span>ebsite <span>T</span>erms of <span>U</span>se
      </p>

      <p className="title text-center">
        <span>L</span>ast revised on: <span>N</span>ovember <span>18</span>
        <sup>th</sup>, <span>2021</span>
      </p>

      <div className="policy-content">
        <p>
          The website located at https://www.CREMTex.com (the “<span>Site</span>
          ”) belongs to CREMTex Inc.,a Delaware corporation, together with its
          affiliates (“<span>Company</span>”, “<span>us</span>”, “
          <span>our</span>”, and “<span>we</span>”). Certain features of the
          Site may be subject to additional guidelines, terms, or rules, which
          will be posted on the Site in connection with such features. All such
          additional terms, guidelines, and rules are formed by reference into
          these Terms.
        </p>

        <p>
          THESE TERMS OF USE (THESE “<span>TERMS</span>”) SET FORTH THE LEGALLY
          BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY
          ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF
          OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND
          WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO
          THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).
          YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT
          AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS
          OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
        </p>

        <p>
          THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
          CLASSNAME ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE
          EVENT OF A DISPUTE.
        </p>

        <p className="policy-title">1. Accounts</p>

        <p>
          <span>1.1 Account Creation.</span> In order to use certain features of
          the Site, you must register for an account (“<span>Account</span>”)
          and provide certain information about yourself as prompted by the
          account registration form. You represent and warrant that: (a) all
          required registration information you submit is truthful and accurate;
          (b) you will maintain the accuracy of such information and (c) that
          you are in compliance with the applicable law, legislation,
          regulations, and rules in your Country of residence or any other
          jurisdiction applicable to you.You may delete your Account at any
          time, for any reason, by following the instructions on the Site.
          Company may suspend or terminate your Account in accordance with
          Section 8.
        </p>

        <p>
          <span>1.2 Account Verification.</span> If we have an account, to
          finish creating your account with us, you shall provide us with the
          information we request. We collect, verify and retain information for
          the purposes of identity verification and the detection of money
          laundering, terrorist financing, fraud, or any other financial crime.
          Such information may include your name, address, telephone number,
          email address, date of birth, banking information, and a picture of
          yourself holding your government-issued identification card. We may
          also request information regarding the source of your funds,
          income/employment information, and any additional information required
          for to comply with Applicable Laws and Regulations. When you provide
          information to us, you verify that the information provided is
          accurate and complete, and you agree to notify us in writing of any
          changes made to the information you provide. You hereby authorize us,
          directly or through a third party, to make any inquiries we consider
          necessary to verify your identity and/or protect against fraud,
          including but not limited to: (a) query identity information contained
          in public reports (e.g., your name, address, past addresses, or date
          of birth); and (b) take action we reasonably deem necessary based on
          the results of such inquiries and reports. You further authorize any
          and all third parties to which such inquiries or requests may be
          directed to fully respond to such inquiries or requests. We will have
          no liability or responsibility for any permanent or temporary
          inability to access or use any Services as a result of any identity
          verification or other screening procedures.
        </p>

        <p>
          <span>1.3 Account Responsibilities. </span>You are responsible for
          maintaining the confidentiality of your Account login information and
          are fully responsible for all activities that occur under your
          Account. If you permit others to use your account credentials, you are
          responsible for the activities of such users that occur in connection
          with your account. You will not (i) add or attempt to add any other
          person’s Account information to your account, (ii) use or attempt to
          use another user’s account without authorization from that user and us
          or (iii) impersonate or post on behalf of any person or entity or
          otherwise mispresent your affiliation with a person or entity. You
          agree to immediately notify Company of any unauthorized use, or
          suspected unauthorized use of your Account or any other breach of
          security. Company cannot and will not be liable for any loss or damage
          arising from your failure to comply with the above requirements.
        </p>

        <p className="policy-title">2. Access to the Site</p>

        <p>
          <span>2.1 License. </span>Subject to these Terms, Company grants you a
          non-transferable, non-exclusive, revocable, limited license to use and
          access the Site solely for your own personal, noncommercial use.
        </p>

        <p>
          <span>2.2 Certain Restrictions.</span> The rights granted to you in
          these Terms are subject to the following restrictions: (a) you shall
          not license, sell, rent, lease, transfer, assign, distribute, host, or
          otherwise commercially exploit the Site, whether in whole or in part,
          or any content displayed on the Site; (b) you shall not modify, make
          derivative works of, disassemble, reverse compile or reverse engineer
          any part of the Site; (c) you shall not access the Site in order to
          build a similar or competitive website, product, or service; and (d)
          except as expressly stated herein, no part of the Site may be copied,
          reproduced, distributed, republished, downloaded, displayed, posted or
          transmitted in any form or by any means. Unless otherwise indicated,
          any future release, update, or other addition to functionality of the
          Site shall be subject to these Terms. All copyright and other
          proprietary notices on the Site (or on any content displayed on the
          Site) must be retained on all copies thereof.
        </p>

        <p>
          <span>2.3 Modification. </span>Company reserves the right, at any
          time, to modify, suspend, or discontinue the Site (in whole or in
          part) with or without notice to you. You agree that Company will not
          be liable to you or to any third party for any modification,
          suspension, or discontinuation of the Site or any part thereof.
        </p>

        <p>
          <span>2.4 No Support or Maintenance.</span> You acknowledge and agree
          that Company will have no obligation to provide you with any support
          or maintenance in connection with the Site.
        </p>

        <p>
          <span>2.5 Ownership.</span> Excluding any User Content that you may
          provide (defined below), you acknowledge that all the intellectual
          property rights, including copyrights, patents, trademarks, and trade
          secrets, in the Site and its content are owned by Company or Company’s
          suppliers. Neither these Terms (nor your access to the Site) transfers
          to you or any third party any rights, title or interest in or to such
          intellectual property rights, except for the limited access rights
          expressly set forth in Section 3.1. Company and its suppliers reserve
          all rights not granted in these Terms. There are no implied licenses
          granted herein.
        </p>

        <p className="policy-title">3. User Content</p>

        <p>
          <span>3.1 User Content. “User Content” </span>means any and all
          information and content that a user submits to, or uses with, the Site
          (e.g., content in the user’s profile or postings). You are solely
          responsible for your User Content. You assume all risks associated
          with use of your User Content, including any reliance on its accuracy,
          completeness or usefulness by others, or any disclosure of your User
          Content that personally identifies you or any third party. You hereby
          represent and warrant that your User Content does not violate our
          Acceptable Use Policy (defined in Section 3.3). You may not represent
          or imply to others that your User Content is in any way provided,
          sponsored or endorsed by Company. Because you alone are responsible
          for your User Content, you may expose yourself to liability if, for
          example, your User Content violates the Acceptable Use Policy. Company
          is not obligated to backup any User Content, and your User Content may
          be deleted at any time without prior notice. You are solely
          responsible for creating and maintaining your own backup copies of
          your User Content if you desire.
        </p>

        <p>
          <span>3.2 License.</span> You hereby grant (and you represent and
          warrant that you have the right to grant) to Company an irrevocable,
          nonexclusive, royalty-free and fully paid, worldwide license to
          reproduce, distribute, publicly display and perform, prepare
          derivative works of, incorporate into other works, and otherwise use
          and exploit your User Content, and to grant sublicenses of the
          foregoing rights, solely for the purposes of including your User
          Content in the Site. You hereby irrevocably waive (and agree to cause
          to be waived) any claims and assertions of moral rights or attribution
          with respect to your User Content.
        </p>

        <p>
          <span>3.3 Acceptable Use Policy.</span> The following terms constitute
          our <span>“Acceptable Use Policy”:</span>
        </p>

        <p>
          <span>(a)</span> You agree not to use the Site to collect, upload,
          transmit, display, or distribute any User Content (i) that violates
          any third-party right, including any copyright, trademark, patent,
          trade secret, moral right, privacy right, right of publicity, or any
          other intellectual property or proprietary right; (ii) that is
          unlawful, predatory, harassing, abusive, tortious, threatening,
          harmful, invasive of another’s privacy, vulgar, defamatory, false,
          intentionally misleading, trade libelous, pornographic, obscene,
          patently offensive, promotes racism, bigotry, hatred, or physical harm
          of any kind against any group or individual or is otherwise
          objectionable; (iii) that is harmful to minors in any way; or (iv)
          that is in violation of any law, regulation, or obligations or
          restrictions imposed by any third party.
        </p>

        <p>
          <span>(b)</span> In addition, you agree not to: (i) upload, transmit,
          or distribute to or through the Site any computer viruses, worms, or
          any software intended to damage or alter a computer system or data;
          (ii) send through the Site unsolicited or unauthorized advertising,
          promotional materials, junk mail, spam, chain letters, pyramid
          schemes, or any other form of duplicative or unsolicited messages,
          whether commercial or otherwise; (iii) use the Site to harvest,
          collect, gather or assemble information or data regarding other users,
          including e-mail addresses, without their consent; (iv) interfere
          with, disrupt, or create an undue burden on servers or networks
          connected to the Site, or violate the regulations, policies or
          procedures of such networks; (v) attempt to gain unauthorized access
          to the Site (or to other computer systems or networks connected to or
          used together with the Site), whether through password mining or any
          other means; (vi) harass or interfere with any other user’s use and
          enjoyment of the Site; or (vi) use software or automated agents or
          scripts to produce multiple accounts on the Site, or to generate
          automated searches, requests, or queries to (or to strip, scrape, or
          mine data from) the Site (provided, however, that we conditionally
          grant to the operators of public search engines revocable permission
          to use spiders to copy materials from the Site for the sole purpose of
          and solely to the extent necessary for creating publicly available
          searchable indices of the materials, but not caches or archives of
          such materials, subject to the parameters set forth in our robots.txt
          file).
        </p>

        <p>
          <span>3.4 Enforcement.</span> We reserve the right (but have no
          obligation) to review any User Content, and to investigate and/or take
          appropriate action against you in our sole discretion if you violate
          the Acceptable Use Policy or any other provision of these Terms or
          otherwise create liability for us or any other person.Such action may
          include removing or modifying your User Content, terminating your
          Account in accordance with Section 8, and/or reporting you to law
          enforcement authorities.
        </p>

        <p>
          <span>3.5 Feedback.</span> If you provide Company with any feedback or
          suggestions regarding the Site (“<span>Feedback</span>”), you hereby
          assign to Company all rights in such Feedback and agree that Company
          shall have the right to use and fully exploit such Feedback and
          related information in any manner it deems appropriate. Company will
          treat any Feedback you provide to Company as non-confidential and
          non-proprietary. You agree that you will not submit to Company any
          information or ideas that you consider to be confidential or
          proprietary.
        </p>

        <p className="policy-title">4. Indemnification.</p>

        <p>
          YOU AGREE TO INDEMNIFY AND HOLD COMPANY (AND ITS OFFICERS, EMPLOYEES,
          ATTORNEYS, AND AGENTS) HARMLESS, INCLUDING COSTS AND ATTORNEYS’ FEES,
          FROM ANY CLAIM OR DEMAND MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT
          OF (A) YOUR USE OF THE SITE, (B) YOUR VIOLATION OF THESE TERMS, (C)
          YOUR VIOLATION OF APPLICABLE LAWS OR REGULATIONS OR (D) YOUR USER
          CONTENT. YOU AGREE TO PROMPTLY NOTIFY COMPANY OF ANY THIRD-PARTY
          CLAIMS. COMPANY RESERVES THE RIGHT, AT YOUR EXPENSE, TO ASSUME THE
          EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER FOR WHICH YOU ARE REQUIRED
          TO INDEMNIFY US, AND YOU AGREE TO COOPERATE WITH OUR DEFENSE OF THESE
          CLAIMS. YOU AGREE NOT TO SETTLE ANY MATTER WITHOUT THE PRIOR WRITTEN
          CONSENT OF COMPANY. COMPANY WILL USE REASONABLE EFFORTS TO NOTIFY YOU
          OF ANY SUCH CLAIM, ACTION OR PROCEEDING UPON BECOMING AWARE OF IT.
        </p>

        <p className="policy-title">5. Third-Party Links & Ads; Other Users</p>

        <p>
          <span>5.1 Third-Party Links & Ads. </span>The Site may contain links
          to third-party websites and services, and/or display advertisements
          for third parties (collectively, “<span>Third-Party Links & Ads</span>
          ”). Such Third-Party Links & Ads are not under the control of Company,
          and Company is not responsible for any Third-Party Links & Ads.
          Company provides access to these Third-Party Links & Ads only as a
          convenience to you, and does not review, approve, monitor, endorse,
          warrant, or make any representations with respect to Third-Party Links
          & Ads. You use all Third-Party Links & Ads at your own risk and should
          apply a suitable level of caution and discretion in doing so. When you
          click on any of the Third-Party Links & Ads, the applicable third
          party’s terms and policies apply, including the third party’s privacy
          and data gathering practices. You should make whatever investigation
          you feel necessary or appropriate before proceeding with any
          transaction in connection with such Third-Party Links & Ads. The
          Company is not responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any content, information, events, goods or
          services available by or through such Third Party.
        </p>

        <p>
          <span>5.2 Other Users.</span> Each Site user is solely responsible for
          any and all of its own User Content. Because we do not control User
          Content, you acknowledge and agree that we are not responsible for any
          User Content, whether provided by you or by others. We make no
          guarantees regarding the accuracy, currency, suitability, or quality
          of any User Content. Your interactions with other Site users are
          solely between you and such users. You agree that Company will not be
          responsible for any loss or damage incurred as the result of any such
          interactions. If there is a dispute between you and any Site user, we
          are under no obligation to become involved.
        </p>

        <p>
          <span>5.3 Release. </span>You hereby release and forever discharge the
          Company (and our officers, employees, attorneys, agents, successors,
          and assigns) from, and hereby waive and relinquish, each and every
          past, present and future dispute, claim, controversy, demand, right,
          obligation, liability, action and cause of action of every kind and
          nature (including personal injuries, death, and property damage), that
          has arisen or arises directly or indirectly out of, or that relates
          directly or indirectly to, the Site (including any interactions with,
          or act or omission of, other Site users or any Third-Party Links &
          Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
          CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH
          STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
          CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
          TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
          MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
        </p>

        <p className="policy-title">6. Disclaimers</p>

        <p>
          THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND
          COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
          INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
          NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE
          SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE
          ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE,
          LEGAL, OR SAFE.
        </p>

        <p>
          NEITHER COMPANY NOR THE SERVICES PROVIDE OR INTEND TO PROVIDE LEGAL,
          TAX OR FINANCIAL ADVICE. COMPANY IS NOT A FINANCIAL PLANNER, BROKER OR
          TAX ADVISOR. BEFORE USING THIS SITE, YOU SHOULD SEEK ADVICE FROM YOUR
          ACCOUNTANT OR OTHER FINANCIAL ADVISERS WHO ARE FULLY AWARE OF YOUR
          INDIVIDUAL FINANCIAL CIRCUMSTANCES.
        </p>

        <p className="policy-title">7. Limitation on Liability</p>

        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR
          OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
          PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR
          ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
          PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE
          OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE IS AT
          YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
          ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA
          RESULTING THEREFROM.
        </p>

        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
          THE CONTRARY CONTAINED HEREIN, AND IN THE EVENT THE LIMITATION OF
          LIABILITY IS INAPPLICABLE PURSUANT TO APPPLICABLE LAW, OUR LIABILITY
          TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR
          ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL
          AT ALL TIMES BE LIMITED TO THE GREATER OF FIFTY US DOLLARS (U.S. $50)
          OR THE AMOUNT YOU PAID TO USE OUR SERVICES. THE EXISTENCE OF MORE THAN
          ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS
          WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
          AGREEMENT.
        </p>

        <p className="policy-title">8. Term and Termination.</p>

        <p>
          Subject to this Section, these Terms will remain in full force and
          effect while you use the Site. We may suspend or terminate your rights
          to use the Site (including your Account) at any time for any reason at
          our sole discretion, including for any use of the Site in violation of
          these Terms. Upon termination of your rights under these Terms, your
          Account and right to access and use the Site will terminate
          immediately. You understand that any termination of your Account may
          involve deletion of your User Content associated with your Account
          from our live databases. Company will not have any liability
          whatsoever to you for any termination of your rights under these
          Terms, including for termination of your Account or deletion of your
          User Content. Even after your rights under these Terms are terminated,
          the following provisions of these Terms will remain in effect:
          Sections 3.2 through 3.5, Section 4 and Sections 5 through 11.
        </p>

        <p className="policy-title">9. Taxes</p>

        <p>
          You are responsible for determining any and all taxes assessed,
          incurred, or required to be collected, paid, or withheld, in
          connection with your use of the Service. You are solely responsible
          for collecting, withholding, reporting and remitting any taxes to the
          appropriate tax authority. Company is not obligated to, and will not,
          determine whether taxes apply, or calculate, collect, report or remit
          any taxes to any tax authority arising from your use of the Service.
        </p>

        <p className="policy-title">
          10. No Financial Services Activities or Advice
        </p>

        <p>
          <span>10.1 No Offer; No Solicitation.</span> The information and any
          materials contained in the Site should not be considered as an offer
          or solicitation to buy or sell financial instruments, provide
          financial advice, create a trading platform, facilitate or take
          deposits or provide any other financial services of any kind in any
          jurisdiction.
        </p>

        <p>
          <span>10.2 No Advice. </span>The information contained on or in the
          Site is not intended to provide and should not be construed as advice
          of any kind. You shall not use the Site as a substitute for
          independent investigations and competent financial judgement and you
          should obtain appropriate professional advice when necessary. It does
          not take into account your objectives, financial situation or needs.
        </p>

        <p className="policy-title">
          11. Acknowledgement of Digital Asset risks (if applicable)
        </p>

        <p>
          <span>11.1 Digital Assets May Lose Value. </span>You has carefully
          reviewed, acknowledge, understand and assume the following risks, as
          well as all other risks associated with digital assets like Bitcoin
          (including those not discussed herein), all of which could render your
          digital assets worthless or of little value.
        </p>

        <p>
          <span>11.2 Your Sole Risk. </span>Investing in digital assets is at
          your sole risk and any digital assets purchased are provided, used and
          acquired on an “AS IS” and on an “AS AVAILABLE” basis without
          representations, warranties, promises or guarantees whatsoever of any
          kind by Company. You must rely on your own examination and
          investigation thereof. Further, real estate ventures, cryptocurrency
          hosting, cryptocurrency mining, energy creation, and investing are all
          risky ventures.
        </p>

        <p>
          <span>11.3 Purchase Price Risk. </span>There are no guarantees as to
          the price of digital assets purchased by you and no guarantees that
          the price per digital assets determined by the market will be equal to
          or higher than your purchase price. There is the possibility that the
          price per digital assets may fall below the price paid by you.
        </p>

        <p>
          <span>11.4 Ability to Transact or Resell. </span>You may be unable to
          sell or otherwise transact in digital assets at any time, or for the
          price you paid due to (a) diminution in value of the digital assets;
          (b) lack of liquidity for the digital assets; or (c) restrictions on
          the transferability of the digital assets.
        </p>

        <p>
          <span>11.5 Regulatory Framework. </span>The cryptocurrency technology
          is new. It is difficult to predict how or whether governmental
          authorities may regulate such technologies. It is likewise difficult
          to predict how or whether any governmental authority may make changes
          to existing laws, regulations or rules that may affect our technology
          and its applications. Such changes could negatively impact digital
          assets in various ways, including, for example, through a
          determination that digital assets are a regulated financial instrument
          that requires registration.
        </p>

        <p>
          <span>11.6 Risk of Government Action. </span>The digital asset
          industry is new, and may be subject to heightened oversight and
          scrutiny, including investigations or enforcement actions. There can
          be no assurance that governmental authorities will not examine the
          operations of digital assets or pursue enforcement actions against
          digital assets.
        </p>

        <p className="policy-title">
          12. ACKNOWLEDGEMENT OF RISKS WITH TECHNOLOGY
        </p>

        <p>
          <span>12.1</span> You must be at least 18 years old. To use the
          services, you must create an account and take action when certain
          events take place, including having an email with safe password. You
          must provide accurate information. If your information has been given
          to a third party that is not approved to hold the information, you
          must inform us immediately. Do not inform others of your password. You
          are responsible for your username and password, account and user-name,
          or registration, if by you or others . We are given authority to act
          on data received through your use of our site, and are not responsible
          for any loss or damage. You agree to be part of our mailing list
          unless you are part of a state that requires certain approval prior to
          joining mailing lists.
        </p>

        <p>
          <span>12.2 </span>You must use this site at your own risk. We make no
          guarantees, representations, or warranties that our services or data
          available on our site, or that the use of or result of the use of the
          services, will be accurate, reliable, complete, current,
          uninterrupted, or without errors. All information available on the
          site are for informational purposes only, and may not represent the
          current condition of a Property or asset. The posting of pictures or
          videos or any other items on the site does not constitute a guarantee
          that any items represented when a buyer takes the property. You must
          conduct your own due diligence and investigate all matters relating to
          any companies. It is recommended that you seek legal, accounting,
          consulting or any other advice, for due diligence and that you use
          good faith efforts in determining whether the information is accurate.
        </p>

        <p>
          <span>12.3 </span>The information provided through the site is subject
          to change and you acknowledge this fact. You must check periodically
          for updated information.
        </p>

        <p>
          <span>12.4 </span>There may be third party content on our site. You
          acknowledge that we cannot assume responsibility for such content,
          services, or information. We do not guaranty or warrant accurate
          information from such third parties.
        </p>

        <p>
          <span>12.5 </span>You understand and acknowledge that you trained and
          understand the risks of engineering. All amounts paid to us are
          non-refundable. You also acknowledge that you have consulted with your
          accountant or legal representative before making any decision to
          participate in any activity on this site. Use at your own risk.
        </p>

        <p className="policy-title">13. Changes. </p>

        <p>
          {" "}
          These Terms are subject to occasional revision, and if we make any
          substantial changes, we may notify you by sending you an e-mail to the
          last e-mail address you provided to us (if any), and/or by prominently
          posting notice of the changes on our Site. You are responsible for
          providing us with your most current e-mail address. In the event that
          the last e-mail address that you have provided us is not valid, or for
          any reason is not capable of delivering to you the notice described
          above, our dispatch of the e-mail containing such notice will
          nonetheless constitute effective notice of the changes described in
          the notice. Any changes to these Terms will be effective upon the
          earlier of thirty (30) calendar days following our dispatch of an
          e-mail notice to you (if applicable) or thirty (30) calendar days
          following our posting of notice of the changes on our Site. These
          changes will be effective immediately for new users of our Site.
          Continued use of our Site following notice of such changes shall
          indicate your acknowledgement of such changes and agreement to be
          bound by the terms and conditions of such changes.
        </p>

        <p>
          <span>14. </span>Dispute Resolution. Please read this Arbitration
          Agreement carefully. It is part of your contract with Company and
          affects your rights. It contains procedures for MANDATORY BINDING
          ARBITRATION AND A CLASS ACTION WAIVER.
        </p>

        <p>
          <span>(a) </span> Applicability of Arbitration Agreement. All claims
          and disputes (excluding claims for injunctive or other equitable
          relief as set forth below) in connection with the Terms or the use of
          any product or service provided by the Company that cannot be resolved
          informally shall be resolved by binding arbitration on an individual
          basis under the terms of this Arbitration Agreement. Unless otherwise
          agreed to, all arbitration proceedings shall be held in English. This
          Arbitration Agreement applies to you and the Company, and to any
          subsidiaries, affiliates, agents, employees, predecessors in interest,
          successors, and assigns, as well as all authorized or unauthorized
          users or beneficiaries of services or goods provided under the Terms.
        </p>

        <p>
          <span>(b) </span>Notice Requirement and Informal Dispute Resolution.
          Before either party may seek arbitration, the party must first send to
          the other party a written Notice of Dispute (“<span>Notice</span>”)
          describing the nature and basis of the claim or dispute, and the
          requested relief. A Notice to the Company should be sent to: 16107
          Kensington Drive, #116, Sugar Land, Texas 77479. After the Notice is
          received, you and the Company may attempt to resolve the claim or
          dispute informally. If you and the Company do not resolve the claim or
          dispute within thirty (30) days after the Notice is received, either
          party may begin an arbitration proceeding. The amount of any
          settlement offer made by any party may not be disclosed to the
          arbitrator until after the arbitrator has determined the amount of the
          award, if any, to which either party is entitled.
        </p>

        <p>
          <span>(c) </span>Arbitration Rules. Arbitration shall be initiated
          through the American Arbitration Association (“<span>AAA</span>”), an
          established alternative dispute resolution provider (“
          <span>ADR Provider</span>”) that offers arbitration as set forth in
          this section. If AAA is not available to arbitrate, the parties shall
          agree to select an alternative ADR Provider. The rules of the ADR
          Provider shall govern all aspects of the arbitration, including but
          not limited to the method of initiating and/or demanding arbitration,
          except to the extent such rules are in conflict with the Terms. The
          AAA Consumer Arbitration Rules (“<span>Arbitration Rules</span>”)
          governing the arbitration are available online at www.adr.orgor by
          calling the AAA at 1-800-778-7879. The arbitration shall be conducted
          by a single, neutral arbitrator. Arbitration proceedings will be held
          in Houston, Texas. Any claims or disputes where the total amount of
          the award sought is less than Ten Thousand U.S. Dollars (US
          $10,000.00) may be resolved through binding non-appearance-based
          arbitration, at the option of the party seeking relief. For claims or
          disputes where the total amount of the award sought is Ten Thousand
          U.S. Dollars (US $10,000.00) or more, the right to a hearing will be
          determined by the Arbitration Rules. Any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction. Each party shall bear its own costs (including
          attorney’s fees) and disbursements arising out of the arbitration and
          shall pay an equal share of the fees and costs of the ADR Provider.
        </p>

        <p>
          <span>(d) </span>
          <i>Additional Rules</i> for <i>Non-Appearance Based Arbitration</i>.
          If non-appearance based arbitration is elected, the arbitration shall
          be conducted by telephone, online and/or based solely on written
          submissions; the specific manner shall be chosen by the party
          initiating the arbitration. The arbitration shall not involve any
          personal appearance by the parties or witnesses unless otherwise
          agreed by the parties.
        </p>

        <p>
          <span>(e) </span>
          <i>Time Limits.</i> If you or the Company pursue arbitration, the
          arbitration action must be initiated and/or demanded within the
          statute of limitations (i.e., the legal deadline for filing a claim)
          and within any deadline imposed under the AAA Rules for the pertinent
          claim.
        </p>

        <p>
          <span>(f) </span> <i>Authority of Arbitrator. </i> If arbitration is
          initiated, the arbitrator will decide the rights and liabilities, if
          any, of you and the Company, and the dispute will not be consolidated
          with any other matters or joined with any other cases or parties. The
          arbitrator shall have the authority to grant motions dispositive of
          all or part of any claim. The arbitrator shall have the authority to
          award monetary damages, and to grant any non-monetary remedy or relief
          available to an individual under applicable law, the AAA Rules, and
          the Terms. The arbitrator shall issue a written award and statement of
          decision describing the essential findings and conclusions on which
          the award is based, including the calculation of any damages awarded.
          The arbitrator has the same authority to award relief on an individual
          basis that a judge in a court of law would have. The award of the
          arbitrator is final and binding upon you and the Company.
        </p>

        <p>
          <span>(g) </span>
          <i>Waiver of Jury Trial. </i>THE PARTIES HEREBY WAIVE THEIR
          CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
          FRONT OF A JUDGE OR A JURY, instead electing that all claims and
          disputes shall be resolved by arbitration under this Arbitration
          Agreement. In the event any litigation should arise between you and
          the Company in any state or federal court in a suit to vacate or
          enforce an arbitration award or otherwise, YOU AND THE COMPANY WAIVE
          ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be
          resolved by a judge.
        </p>

        <p>
          <span>(h) </span>
          <i>Waiver of Class or Consolidated Actions. </i>ALL CLAIMS AND
          DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
          ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
          BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY
          OTHER CUSTOMER OR USER.
        </p>

        <p>
          <span>(i) </span>
          <i>Confidentiality</i> All aspects of the arbitration proceeding,
          including but not limited to the award of the arbitrator and
          compliance therewith, shall be strictly confidential. The parties
          agree to maintain confidentiality unless otherwise required by law.
          This paragraph shall not prevent a party from submitting to a court of
          law any information necessary to enforce this Agreement, to enforce an
          arbitration award, or to seek injunctive or equitable relief.
        </p>

        <p>
          <span>(j) </span>
          <i>Severability.</i> If any part or parts of this Arbitration
          Agreement are found under the law to be invalid or unenforceable by a
          court of competent jurisdiction, then such specific part or parts
          shall be of no force and effect and shall be severed and the remainder
          of the Agreement shall continue in full force and effect.
        </p>

        <p>
          <span>(k) </span>
          <i>Right to Waive. </i>Any or all of the rights and limitations set
          forth in this Arbitration Agreement may be waived by the party against
          whom the claim is asserted. Such waiver shall not waive or affect any
          other portion of this Arbitration Agreement.
        </p>

        <p>
          <span>(l) </span>
          <i>Survival of Agreement. </i>This Arbitration Agreement will survive
          the termination of your relationship with Company.
        </p>

        <p>
          <span>(m) </span>
          <i> Emergency Equitable Relief. </i>Notwithstanding the foregoing,
          either party may seek emergency equitable relief before a state or
          federal court in order to maintain the status quo pending arbitration.
          A request for interim measures shall not be deemed a waiver of any
          other rights or obligations under this Arbitration Agreement.
        </p>

        <p>
          <span>(n) </span>
          <i>Claims Not Subject to Arbitration. </i>Notwithstanding the
          foregoing, claims of defamation, violation of the Computer Fraud and
          Abuse Act, and infringement or misappropriation of the other party’s
          patent, copyright, trademark or trade secrets shall not be subject to
          this Arbitration Agreement.
        </p>

        <p>
          <span>(o) </span>
          <i>Courts.</i> In any circumstances where the foregoing Arbitration
          Agreement permits the parties to litigate in court, the parties hereby
          agree to submit to the personal jurisdiction of the courts located
          within Harris County, Texas, for such purpose
        </p>

        <p>
          <span>14.2 Export. </span>The Site may be subject to U.S. export
          control laws and may be subject to export or import regulations in
          other countries. You agree not to export, reexport, or transfer,
          directly or indirectly, any U.S. technical data acquired from Company,
          or any products utilizing such data, in violation of the United States
          export laws or regulations.
        </p>

        <p>
          <span>14.3 Disclosures. </span>Company is located at the address in
          Section 12.10. If you are a California resident, you may report
          complaints to the Complaint Assistance Unit of the Division of
          Consumer Product of the California Department of Consumer Affairs by
          contacting them in writing at 1625 North Market Blvd., Suite N 112,
          Sacramento, CA 95834, or by telephone at (800) 952-5210.
        </p>

        <p>
          <span>14.4 Electronic Communications. </span>The communications
          between you and Company use electronic means, whether you use the Site
          or send us emails, or whether Company posts notices on the Site or
          communicates with you via email. For contractual purposes, you (a)
          consent to receive communications from Company in an electronic form;
          and (b) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that Company provides to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were be in a hardcopy writing. The foregoing does
          not affect your non-waivable rights.
        </p>

        <p>
          <span>14.5 Entire Terms. </span>These Terms constitute the entire
          agreement between you and us regarding the use of the Site. Our
          failure to exercise or enforce any right or provision of these Terms
          shall not operate as a waiver of such right or provision. The section
          titles in these Terms are for convenience only and have no legal or
          contractual effect. The word “including” means “including without
          limitation”. If any provision of these Terms is, for any reason, held
          to be invalid or unenforceable, the other provisions of these Terms
          will be unimpaired and the invalid or unenforceable provision will be
          deemed modified so that it is valid and enforceable to the maximum
          extent permitted by law. These Terms, and your rights and obligations
          herein, may not be assigned, subcontracted, delegated, or otherwise
          transferred by you without Company’s prior written consent, and any
          attempted assignment, subcontract, delegation, or transfer in
          violation of the foregoing will be null and void. Company may freely
          assign these Terms. The terms and conditions set forth in these Terms
          shall be binding upon assignees.
        </p>

        <p>
          <span>14.6 Governing Law. </span>The use of Company’s website and
          platform, your rights and obligations, and all actions contemplated
          by, arising out of, or related to this User Agreement shall be
          governed by the laws of the State of Texas, without giving effect to
          any conflicts of laws principles that require the application of the
          law of a different state.
        </p>

        <p>
          <span>14.7 Severability. </span> If any part or parts of these Terms
          are found under the law to be invalid or unenforceable by a court of
          competent jurisdiction, then such specific part or parts shall be of
          no force and effect and shall be severed and the remainder of the
          Terms shall continue in full force and effect.
        </p>

        <p>
          <span>14.8 Trademark Information. </span>All trademarks, logos and
          service marks (“<span>Marks</span>”) displayed on the Site are our
          property or the property of other third parties. You are not permitted
          to use these Marks without our prior written consent or the consent of
          such third party which may own the Marks.
        </p>

        <p>
          <span>14.9 Contact Information:</span>
        </p>

        <p>
          CREMTex Inc. <br />
          2150 Town Square Pl Suite 200 <br />
          Sugar Land, Texas 77479
        </p>
      </div>
    </div>
  );
}

export default Policy