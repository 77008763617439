import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import env from '../assets/images/banner/environmental.jpg';
import social from '../assets/images/banner/social.jpg';
import gov from '../assets/images/banner/governance.jpg';

const ESG = () => {
  return (
    <>
      <div className="esg-banner bg-white">
        <Container>
          <div className="banner-highlighted-text text-secondary">
            ENVIRONMENTAL, SOCIAL AND GOVERNANCE
          </div>
          <h3 className="banner-title-light">
            Our commitment to building thriving, environmentally friendly
            blockchain infrastructure
          </h3>
          <p className="banner-content-light ps-0">
            At CREMTex, we work at the highest integrity, curate a culture of
            compliance, and seek out opportunities that help benefit our
            clients, employees, and the communities where live and operate
          </p>
        </Container>
      </div>

      <div className="esg-about-wrap">
        <Container>
          <div className="esg-content-wrap">
            <Row className="gy-4">
              <Col lg={5}>
                <div className="esg-img">
                  <img src={env} alt="environmental" />
                </div>
              </Col>
              <Col lg={7}>
                <div className="esg-about-content">
                  <h3 className="banner-title-light">Environmental</h3>
                  <p className="banner-content-light ps-0">
                    We do our part to mine cryptocurrency sustainably, We not
                    only look for renewable energy providers, but also invest in
                    sustainable technology, That can be used to power our mining
                    operations and communities worldwide.
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="esg-content-wrap">
            <Row className="gy-4">
              <Col lg={7}>
                <div className="esg-about-content reverse">
                  <h3 className="banner-title-light">Social</h3>
                  <p className="banner-content-light ps-0">
                    Making a big impact on the world is a fundamental for us, it
                    is at the forethought of every decision we take. Which is
                    why we always hire local where we operate, and give back to
                    the communities by even providing access to the internet so
                    they can also participate in the blockchain revolution where
                    we all linked
                  </p>
                </div>
              </Col>
              <Col lg={5}>
                <div className="esg-img">
                  <img src={social} alt="environmental" />
                </div>
              </Col>
            </Row>
          </div>

          <div className="esg-content-wrap">
            <Row className="gy-4">
              <Col lg={5}>
                <div className="esg-img">
                  <img src={gov} alt="environmental" />
                </div>
              </Col>
              <Col lg={7}>
                <div className="esg-about-content">
                  <h3 className="banner-title-light">Governance</h3>
                  <p className="banner-content-light ps-0">
                    All of our board of directors and employees share the same
                    values. Our decisions are based on how to ensure win-win
                    situations. We make strides to improve our processes, and
                    give a chance to all of stakeholders to have chance to
                    participate the shape of company, and future.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ESG