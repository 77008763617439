import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './assets/sass/style.scss';
import Home from './home/Home';
import Header from './layout/Header';
import Footer from './layout/Footer';
import About from './about/About';
import ESG from './esg/ESG';
import Policy from './policy/Policy';
import ComingSoon from './ComingSoon';


function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
         {/* <Route path='/' element={<ComingSoon />}> */}

        <Route path="/" element={<ComingSoon />} />
        <Route path="*" element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/esg' element={<ESG />} />
        <Route path='/privacy-policy' element={<Policy />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
