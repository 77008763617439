import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";

const ContactForm = () => {
  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [phone, setPhone] = useState(""),
    [subject, setSubject] = useState(""),
    [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://api.gfuturetech.com/api/contact/sendMail", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: "Query from cremtex",
          name: name,
          email: email,
          phone: phone,
          subject: subject,
          message: message,
        }),
      });
    //   let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");

        Swal.fire({
          icon: "success",
          title: "Submitted",
          text: "All the details are collected. We will contact you soon.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Some error occured. Please try again leter.",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-4">
        <Col md={6}>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name *"
            required
          />
        </Col>

        <Col md={6}>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email *"
            required
          />
        </Col>

        <Col md={6}>
          <Form.Control
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number *"
            required
          />
        </Col>

        <Col md={6}>
          <Form.Control
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subject *"
            required
          />
        </Col>

        <Col md={12}>
          <Form.Control
            as="textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Messages *"
            required
            rows={8}
          />
        </Col>

        <Col md={12}>
          <button type="submit" className="btn submit-btn">
            Submit
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
