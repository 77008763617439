import React from 'react'

const Leader = (props) => {
  return (
    <div className="leader-box">
      <img src={props.img} alt="jay hao" className="leader-img" />
      <div className="leader-info">
        <h5 className="leader-name text-center">{props.name}</h5>
        <p className="leader-position text-center">{props.position}</p>
      </div>
    </div>
  );
}

export default Leader