import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import jayHao from "../assets/images/leaders/jayHao.png";
import jay from "../assets/images/leaders/jayy.png";
import deepak from "../assets/images/leaders/deepak.png";
import frank from "../assets/images/leaders/Frank.png";
import anil from "../assets/images/leaders/annil.png";
// import parth from "../assets/images/leaders/parth.png";
import Leader from "../components/Leader";

const About = () => {
  return (
    <>
      <div className="about-banner">
        <Container>
          <h3 className="banner-title-light text-center">ABOUT US</h3>
          <p className="banner-content-light text-center">
            CREMTex is located in the state of Texas, which is considered to be
            the industrial heartland of the United States. Conveniently, this
            offers our company a strategic opportunity to cater to large scale
            mining operations that need inexpensive electricity, infrastructure,
            and business operations to mine both efficiently and effectively.
            CREMTex creates a simple platform for your mining operations by
            offering mining properties, dependable software, mining pool
            options, mining farm hosting, and administration services. Our
            operators will take great care of managing your mining equipment
            both remotely and on-site by providing real-time monitoring,
            troubleshooting, and optimization for all your mining needs.
          </p>
        </Container>
      </div>

      <div className="leaders-wrap">
        <Container>
          <h3 className="banner-title-light text-center">
            LEADERSHIP AND BOARD OF DIRECTORS
          </h3>
          <Row className="g-5">
            {/* <Col md={6} lg={4}>
              <Leader
                img={jayHao}
                name="Jay Hao"
                position="Chairman And Founder"
              />
            </Col> */}

            <Col md={6} lg={4}>
              <Leader img={jay} name="Jay Patel" position="CEO And Founder" />
            </Col>

            <Col md={6} lg={4}>
              <Leader
                img={deepak}
                name="Deepak Sharma"
                position="Board Advisor"
              />
            </Col>

            {/* <Col md={6} lg={4}>
              <Leader
                img={frank}
                name="Frank Monachelli"
                position="Senior VP Of Finance"
              />
            </Col> */}

            <Col md={6} lg={4}>
              <Leader img={anil} name="Anil Ali" position="Legal Counsel" />
            </Col>

            {/* <Col md={6} lg={4}>
              <Leader
                img={parth}
                name="Parth Patel"
                position="VP Of Corporate Development"
              />
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default About;
